@import 'click_and_collect';
@import '../../fredericmalle_base/scss/fredericmalle-base-pc';
@import '_variables';
@import 'checkout';
@import 'account';
@import 'perlgem';
@import 'checkout/checkout-confirmation';
@import 'store_locator';
@import '_bt';
@import '_password_tooltip';
@import 'all';

.product-quickshop__sub-row {
  select,
  a.selectBox {
    .selectBox-label.sku-menu__option {
      @include breakpoint($large-up) {
        font-size: 11px;
      }
    }
  }
}

@import 'applepay';
