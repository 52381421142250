///
/// @file  _fredericmalle-base-pc.scss
///
/// \brief Pull all parts of the theme scss together into one file
///
@import 'style';

:root body.toolbar-drawer.toolbar {
  padding-top: 5.5em !important;
  position: relative;
}
